/* =============================================================================
    JS - forms.js
   ========================================================================== */

//Forms.init();

var Forms = function(){

	var required = '';
	var invalid = '';

	return { init: init, checkForm: checkForm, checkInput: checkInput, placeholder: placeholder };

	function init(){

		Forms.required = '<span data-icon="&#xe019;" class="reqit" style="display:none;"></span>';
		Forms.invalid = '<span data-icon="&#xe017;" class="reqit red" style="display:none;"></span>';

		$('input[required]').parent().css({position: 'relative'});
		$('input[required]').after(Forms.required);
		$('.reqit').fadeIn();

		$('.submitForm').click(function(){
			Forms.checkForm($(this).parent('form'));
		});

	}

	function checkForm($form){
		// Remove all span (icons)
		$('.reqit.red').remove();
		$form.find('input').parent().removeClass('has-error');

		$form.find('input:invalid').each(function(){
			$(this).parent().children('.reqit').remove();
			$(this).parent().addClass('has-error');
		});

		$form.find('input:invalid').after(Forms.invalid);

		$form.find('input[required]:valid').after(Forms.required);

		$('.reqit').fadeIn();
	}

	function checkInput(){

	}

	//Alt Placeholder Inputs
	function placeholder(){

		//INPUTS
		$('.placeholder input').each(function(){
			if($(this).val()){
				$(this).parent().addClass('focused');
			}
		});

		$('.placeholder textarea').each(function(){
			if($(this).val()){
				$(this).parent().addClass('focused');
			}
		});

		$(".placeholder input").focus(function(){
			$(this).parent().addClass("focused");
		}).blur(function(){
			if(!$(this).val()){
				$(this).parent().removeClass("focused");
			}
		});

		$(".placeholder textarea").focus(function(){
			$(this).parent().addClass("focused");
		}).blur(function(){
			if(!$(this).val()){
				$(this).parent().removeClass("focused");
			}
		});

		//SELECTS
		$(".placeholder select").focus(function(){
			$(this).parent().addClass("focused");
		}).blur(function(){
			$(this).parent().removeClass("focused");
		});

	}


}();
